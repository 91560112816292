import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export interface ArrowStatsInterface {
  errors_count: number | null;
  errors_percent: number | null;
  errors_time: number | null;
  prev_errors_count: number | null;
  prev_errors_percent: number | null;
  prev_errors_time: number | null;
}

@Component({
  selector: 'app-arrow',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './arrow.component.html',
  styleUrls: ['./arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowComponent implements OnChanges, AfterViewInit {
  @Input() stats: ArrowStatsInterface | null = null;
  @Input() title: string | undefined;
  @Input() gaugeInfo: string | undefined;
  @Input() scorePageUrl: string | undefined;
  @Input() displayEmpty = false;
  @Input() isRobotOffline = false;
  value: number | '--';
  dir: 'up' | 'down' | 'both' | 'none' = 'none';
  statsPopup = '';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!('stats' in changes || 'isRobotOffline' in changes || 'displayEmpty' in changes)) {
      return;
    }

    if ('displayEmpty' in changes) {
      this.displayEmpty = changes.displayEmpty.currentValue;
    }
    if ('stats' in changes) {
      this.stats = this.displayEmpty ? null : changes.stats.currentValue;
    }
    if ('isRobotOffline' in changes) {
      this.isRobotOffline = changes.isRobotOffline.currentValue;
    }

    if (!this.stats || !Number.isFinite(this.stats.errors_count)) {
      this.value = '--';
      this.dir = 'none';
      this.statsPopup = '';
      return;
    }

    const stats: ArrowStatsInterface = this.stats;
    this.value = stats.errors_count as number;
    const prevValue = stats.prev_errors_count;
    this.dir = prevValue == null ? 'none' :
      this.value > prevValue ? 'up' :
        this.value < prevValue ? 'down' :
          this.value === prevValue ? 'both' : 'none';  // NaN != NaN

    this.statsPopup = this.dir === 'none' ? '' : (
      $localize`Current shift` + ': ' + this.formatGaugeValue(stats.errors_percent, stats.errors_count) + '\n' +
      $localize`Previous shift` + ': ' + this.formatGaugeValue(stats.prev_errors_percent, stats.prev_errors_count)
    );

    this.changeDetectorRef.detectChanges();
  }

  protected formatGaugeValue(percent?: number | null, count?: number | null): string {
    if (percent == null && count == null) {
      return '--';
    }
    if (percent != null && count != null) {
      return `${count} (${percent.toFixed(0)}%)`;
    }
    return percent != null ? `${percent.toFixed(0)}%` : `${count}`;
  }
}
