<div class="arrow" [title]="statsPopup">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.785 30.48">
    <polygon points="12.5 12.8 24.9 .4 37.3 12.8 31.1 12.8 31.1 30.1 18.7 30.1 18.7 12.8 12.5 12.8"
      class="arrow-up" *ngIf="dir === 'up'" />
    <polygon points="37.3 17.7 24.9 30.1 12.5 17.7 18.7 17.7 18.7 .4 31.1 .4 31.1 17.7 37.3 17.7"
      class="arrow-down" *ngIf="dir === 'down'" />
    <path d="M46.2,15.2l-10.4-10.4v5.2H14v-5.2L3.6,15.2l10.4,10.4v-5.2h21.8v5.2l10.4-10.4Z"
      class="arrow-both" *ngIf="dir === 'both'" />
  </svg>
</div>
<div class="value" [class.inactive]="isRobotOffline">
  <ng-container *ngIf="scorePageUrl">
    <a href="{{ scorePageUrl }}">
      {{value}}
    </a>
  </ng-container>
  <ng-container *ngIf="!scorePageUrl">
    {{value}}
  </ng-container>
</div>
<div class="info" [class.inactive]="isRobotOffline" *ngIf="!!gaugeInfo">{{gaugeInfo}}</div>
<div class="title" [class.inactive]="isRobotOffline" *ngIf="!!title">{{title}}</div>
